import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { Worker } from "src/app/core/interfaces/worker.interface";
import { NotificationService } from "src/app/core/services/notification.service";
import { SetError } from "src/app/features/admin/admin.state";
import { DispatchService } from "../services/dispatch.service";
import {
	AssignWorker,
	AssignWorkerOverrideRule,
	BumpWorker,
	CancelBumpWorker,
	CancelReplaceWorker,
	GetAllWorkers,
	GetOrderDetails,
	LoadDispatchOrdersList,
	ReplaceWorker,
	ResetWorkerAndOverr,
	SelectJob,
	SendJobAssignments,
	UnAssignWorker,
	UpdateAssignmentNote,
} from "./dispatch.actions";

export class DispatchStateModel {
	public items: any[];
	public totalOrders: number;
	public loading: boolean;
	public workersLoading: boolean;
	public allWorkers: Worker[];
	public allAssignments: any;
	public allAssignmentsOver: any[];
	public order: any;
	public jobRequests: any;
	public selectedJob: any;
	public allAvailableJobs: number;
}

@State<DispatchStateModel>({
	name: "dispatch",
	defaults: {
		loading: false,
		workersLoading: false,
		allWorkers: null,
		allAssignments: [],
		allAssignmentsOver: [],
		allAvailableJobs: 0,
		order: {},
		jobRequests: [],
		selectedJob: 0,
		items: [],
		totalOrders: 0,
	}
})
@Injectable()
export class DispatchState {
	constructor(
		private dispatchService: DispatchService,
		private notificationService: NotificationService
	) { }

	@Selector() static allWorkers(state: DispatchStateModel) { return state.allWorkers; }
	@Selector() static order(state: DispatchStateModel) { return state.order; }
	@Selector() static loading(state: DispatchStateModel) { return state.loading; }
	@Selector() static jobRequests(state: DispatchStateModel) { return state.jobRequests; }
	@Selector() static jobRequestsCount(state: DispatchStateModel) { return state.jobRequests.length; }
	@Selector() static selectedJob(state: DispatchStateModel) { return state.selectedJob; }
	@Selector() static allAssignments(state: DispatchStateModel) { return state.allAssignments; }
	@Selector() static allAvailableJobs(state: DispatchStateModel) { return state.allAvailableJobs; }
	@Selector() static items(state: DispatchStateModel) { return state.items; }
	@Selector() static totalOrders(state: DispatchStateModel) { return state.totalOrders; }
	@Selector() static workersLoading(state: DispatchStateModel) { return state.workersLoading; }
	@Selector() static allAssignmentsByCurrentSelectedJob(state: DispatchStateModel) { 
		return state.allAssignments.filter(x => x.jobId === state.selectedJob.id);
	}
	@Selector() static unassignedWorkers(state: DispatchStateModel) {
		const isWorkerAssigned = workerId => {
			const found = state.allAssignments.find(a => {
				if (a.workerId === workerId) return true;
				else {
					const foundReplace = a.replacements.find(r => {
						return r.workerId === workerId;
					});
					return foundReplace ? true : false;
				}
			});
			return found ? true : false;
		};

		return state.allWorkers?.filter(worker => {
			return !isWorkerAssigned(worker.id);;
		});
	}

	@Action(SelectJob)
	selectJob(ctx: StateContext<DispatchStateModel>, action: SelectJob) {
		const state = ctx.getState();
		ctx.patchState({
			selectedJob: state.jobRequests[action.index]
		});
	}

	@Action(AssignWorker)
	assign(ctx: StateContext<DispatchStateModel>, { worker }) {
		const state = ctx.getState();
		let updatedJobRequests = state.jobRequests.map(job => {
			if (state.selectedJob.id === job.id) {
				const newAssignments = [...job.jobAssignments, { worker }];
				return { ...job, jobAssignments: newAssignments };
			} else {
				return job;
			}
		});

		ctx.patchState({
			allAssignments: [
				...state.allAssignments,
				{
					workerId: worker.id,
					jobId: state.selectedJob.id,
					firstName: worker.firstName,
					lastName: worker.lastName,
					jobName: state.selectedJob.name,
					replacements: [],
					workerDispatchNotes: worker.workerDispatchNotes
				}
			],
			selectedJob: {
				...state.selectedJob,
				jobAssignments: [...state.selectedJob.jobAssignments, { worker }]
			},
			jobRequests: updatedJobRequests
		});
	}

	@Action(AssignWorkerOverrideRule) 
	assignWorkerOverrideRule(ctx: StateContext<DispatchStateModel>, { worker, comments }) {
		const state = ctx.getState();

		const isValue = state.allAssignmentsOver.indexOf((item: any) => item.worker.id == worker.id)
		let newAllAssignmentsOver = [...state.allAssignmentsOver];
		if(isValue == -1) {
			newAllAssignmentsOver.push({worker, comments})
		}
		
		ctx.patchState({
			allAssignmentsOver: [...newAllAssignmentsOver]
		})
	}

	@Action(ResetWorkerAndOverr)
	resetWorkerAndOverr(ctx: StateContext<DispatchStateModel>) {
		ctx.patchState({
			allAssignments: [],
			allAssignmentsOver: []
		})
	}

	@Action(UnAssignWorker)
	unassign(ctx: StateContext<DispatchStateModel>, { worker }) {
		const state = ctx.getState();
		let updatedJobRequests = state.jobRequests.map(job => {
			if (state.selectedJob.id === job.id) {
				const newAssignments = job.jobAssignments.filter(a => {
					return a.worker.id !== worker.id;
				});
				return { ...job, jobAssignments: newAssignments };
			} else {
				return job;
			}
		});

		const newAllAssignments = state.allAssignments.filter(a => {
			return a.workerId !== worker.id;
		});

		const newAllAssignmentsOv = state.allAssignmentsOver.filter(a => {
			return a.worker.id !== worker.id;
		});

		const newSelectedJob = {
			...state.selectedJob,
			jobAssignments: state.selectedJob.jobAssignments.filter(a => {
				return a.worker.id !== worker.id;
			})
		};

		ctx.patchState({
			allAssignments: newAllAssignments,
			selectedJob: newSelectedJob,
			jobRequests: updatedJobRequests,
			allAssignmentsOver: newAllAssignmentsOv
		});
	}

	@Action(GetOrderDetails)
	getOrderDetails(ctx: StateContext<DispatchStateModel>, action: GetOrderDetails) {
		ctx.patchState({ loading: true, order: {} });
		
		return this.dispatchService.getOrder(action.orderId).pipe(
			tap((res: any) => {
				let assignments = [];
				let joinedNumberOfWorkers = 0;

				res.jobRequests.forEach(job => {
					job = { ...job }; // Added this line because tests return readonly error...
					joinedNumberOfWorkers += job.numberOfWorkers;
					job.jobAssignments.forEach((assignment, i) => {
						let newAssignment = {
							workerId: assignment.worker.id,
							worker: assignment.worker,
							jobId: job.id,
							id: assignment.id,
							firstName: assignment.worker.firstName,
							lastName: assignment.worker.lastName,
							jobName: job.name,
							replacements: [],
							hasBeenReplaced: assignment.replacements?.length > 0 ? true : false,
							workerDispatchNotes: assignment.workerDispatchNotes,
							workerUnions: assignment.worker.workerUnions
						};
						assignment.replacements?.forEach((replace, index) => {
							newAssignment.replacements.push({
								workerId: replace.worker.id,
								worker: replace.worker,
								jobId: job.id,
								id: replace.id,
								firstName: replace.worker.firstName,
								lastName: replace.worker.lastName,
								jobName: job.name,
								hasBeenReplaced: index == assignment.replacements?.length - 1 ? false : true,
								workerDispatchNotes: replace.workerDispatchNotes,
								workerUnions: replace.worker.workerUnions
							});
						});
						assignments.push(newAssignment);
					});

					job.jobAssignments = assignments.filter(j => j.jobId == job.id);
				});

				const state = ctx.getState();
				const selectedJobIndex: number = res.jobRequests.findIndex((e) => {
					return state.selectedJob?.id == e.id;
				});
				ctx.patchState({
					selectedJob: selectedJobIndex > -1 ? res.jobRequests[selectedJobIndex] : null,
					jobRequests: res.jobRequests,
					allAssignments: assignments,
					allAvailableJobs: joinedNumberOfWorkers,
					order: res,
					loading: false
				});
				ctx.dispatch(
					new GetAllWorkers({
						unionId: res.union.id,
						value: "",
						skipCount: 0,
						maxCount: 30,
						date: res.startDate
					})
				);
			})
		);
	}

	@Action(GetAllWorkers)
	getAllWorkers(ctx: StateContext<DispatchStateModel>, action: GetAllWorkers) {
		ctx.patchState({
			allWorkers: null,
			workersLoading: true
		});

		const orderId: string = ctx.getState().order.id;

		return this.dispatchService.getWorkers(
			action.workerSearch.value,
			action.workerSearch.unionId,
			action.workerSearch.date,
			orderId
		).pipe(
			tap(response => {
				ctx.patchState({
					allWorkers: response.items,
					loading: false,
					workersLoading: false,
				});
			})
		);
	}

	@Action(SendJobAssignments)
	postJobAssignments(ctx: StateContext<DispatchStateModel>, action: SendJobAssignments) {
		const state = ctx.getState();

		const assignments = [];
		state.allAssignments.forEach(a => {
			assignments.push({ ...a, dispatchJobRequestId: a.jobId });
			a.replacements.forEach(replace => {
				assignments.push({ ...replace, dispatchJobRequestId: a.jobId });
			});
		});

		const override = state.allAssignmentsOver.map((item: any) => {
			return {
				workerId: item.worker.id,
				comments: item.comments
			}
		})

		return this.dispatchService.assignJobs(action.orderId, {
			assignments: assignments,
			dispatcherComments: action.dispatcherComments,
			hasAgreedCheckCertifications: action.hasAgreedCheckCertifications,
			hasAgreedAssignedSameStartTime: action.hasAgreedAssignedSameStartTime,
			assignmentsWithComments: override
		}).pipe(
			tap(
				response => {
					this.notificationService.showSuccess("Saved correctly.");
					action.callback(null);
				},
				error => {
					action.callback(error);
				}
			)
		);
	}

	@Action(LoadDispatchOrdersList)
	loadDispatchOrderList(ctx: StateContext<DispatchStateModel>, { skipCount, maxResult, filters }) {
		ctx.patchState({ items: [], loading: true });

		return this.dispatchService.getDispatchOrdersList(skipCount, maxResult, filters?.items || {}).subscribe(
			(response) => {
				ctx.patchState({
					items: response.items,
					totalOrders: response.totalCount,
					loading: false
				});
			},
			(error) => {
				ctx.patchState({ items: [], loading: false });
				ctx.dispatch(new SetError({ loading: true }));
			}
		);
	}

	@Action(ReplaceWorker)
	replaceWorker(ctx: StateContext<DispatchStateModel>, {
		dispatchOrderId,
		dispatchJobRequestId,
		workerId,
		replacedWorkerId,
		doGetDetail,
		hasAgreedCheckCertifications,
		hasAgreedAssignedSameStartTime,
		hasAgreedAlreadyAssigned,
		replacementDate,
		isQuitReplacement,
		callback
	}) {
		ctx.patchState({ loading: true });
		const state = ctx.getState();
		return this.dispatchService.replaceWorker(
			dispatchOrderId,
			dispatchJobRequestId,
			workerId,
			replacedWorkerId,
			hasAgreedCheckCertifications,
			hasAgreedAssignedSameStartTime,
			hasAgreedAlreadyAssigned,
			replacementDate,
			isQuitReplacement
		).pipe(
			tap(
				(response) => {
					if (doGetDetail) {
						ctx.dispatch(new GetOrderDetails(state.order.id));
					} 
					callback({ assignmentStatus: response });
					ctx.patchState({ loading: false });
				},
				(error) => {
					callback(error);
					ctx.patchState({ loading: false });
				}
			)
		);
	}

	@Action(CancelReplaceWorker)
	cancelReplaceWorker(ctx: StateContext<DispatchStateModel>, action: CancelReplaceWorker) {
		ctx.patchState({ loading: true });
		const state = ctx.getState();
		return this.dispatchService.cancelReplaceWorker(action.jobAssignmentId).pipe(
			tap(
				(res: any) => {
					if (action.doGetDetail) {
						ctx.dispatch(new GetOrderDetails(state.order.id));
					}
					action.callbackSuccess();
					ctx.patchState({ loading: false });
				},
				error => {
					action.callbackError(error);
					ctx.patchState({ loading: false });
				}
			)
		);
	}

	@Action(UpdateAssignmentNote)
	updateAssignmentNote(ctx: StateContext<DispatchStateModel>, action: UpdateAssignmentNote) {
		return this.dispatchService.updateAssignmentNote(action.dispatchOrderId, action.workerId, action.note).pipe(
			tap(
				(response) => {
					const state = ctx.getState();
					let updatedAssignments: Array<any> = JSON.parse(JSON.stringify(state.allAssignments));
					const assignmentIndex = updatedAssignments.findIndex((e) => {
						return e.workerId === action.workerId;
					});

					if (assignmentIndex > -1) {
						updatedAssignments[assignmentIndex].workerDispatchNotes = action.note;
					} else {
						updatedAssignments = updatedAssignments.map((e) => {
							e.replacements = e.replacements.map((f) => {
								if (f.workerId === action.workerId) {
									f.workerDispatchNotes = action.note;
								}
								return f;
							})
							return e;
						});
					}

					ctx.patchState({
						allAssignments: updatedAssignments
					});

					if (state.selectedJob) {
						let selectedJobAssignments: Array<any> = JSON.parse(JSON.stringify(state.selectedJob.jobAssignments));
						const assignmentIndex = selectedJobAssignments.findIndex((e) => {
							return e.workerId === action.workerId || e.worker.id === action.workerId;
						});
	
						if (assignmentIndex > -1) {
							selectedJobAssignments[assignmentIndex].workerDispatchNotes = action.note;
						} else {
							selectedJobAssignments = selectedJobAssignments.map((e) => {
								e.replacements = e.replacements?.map((f) => {
									if (f.workerId === action.workerId) {
										f.workerDispatchNotes = action.note;
									}
									return f;
								})
								return e;
							});
						}
						
						ctx.patchState({
							selectedJob: {
								...state.selectedJob,
								jobAssignments: selectedJobAssignments
							}
						});

						let updatedAllWorkers: Worker[] = JSON.parse(JSON.stringify(state.allWorkers));
						updatedAllWorkers = updatedAllWorkers.map((e) => {
							if (e.id === action.workerId) {
								e.workerDispatchNotes = action.note;
							}
							return e;
						})

						ctx.patchState({
							allWorkers: updatedAllWorkers
						});
					}
				}
			)
		);
	}

	@Action(BumpWorker)
	bumpWorker(ctx: StateContext<DispatchStateModel>, {
		dispatchOrderId,
		dispatchJobRequestId,
		workerId,
		bumpedWorkerId,
		bumpedDate,
		hasAgreedCheckCertifications,
		hasAgreedAssignedSameStartTime,
		hasAgreedAlreadyAssigned,
		callback
	}) {
		ctx.patchState({ loading: true });
		const state = ctx.getState();
		return this.dispatchService.bumpWorker(
			dispatchOrderId,
			dispatchJobRequestId,
			workerId,
			bumpedWorkerId,
			hasAgreedCheckCertifications,
			hasAgreedAssignedSameStartTime,
			hasAgreedAlreadyAssigned,
			bumpedDate
		).pipe(
			tap(
				(response) => {
					callback({ assignmentStatus: response });
					ctx.patchState({ loading: false });
				},
				(error) => {
					callback(error);
					ctx.patchState({ loading: false });
				}
			)
		);
	}

	@Action(CancelBumpWorker)
	cancelBumpWorker(ctx: StateContext<DispatchStateModel>, action: CancelBumpWorker) {
		ctx.patchState({ loading: true });
		return this.dispatchService.cancelBumpWorker(action.jobAssignmentId).pipe(
			tap(
				(res: any) => {
					action.callbackSuccess();
					ctx.patchState({ loading: false });
				},
				error => {
					action.callbackError(error);
					ctx.patchState({ loading: false });
				}
			)
		);
	}

}
